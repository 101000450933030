import type { ReactNode } from "react"
import type { AppConfigState } from "@/src/config/appConfigTypes"
import type {
    AppConfig,
    AppConfigStatusType,
    AppConfigStatusModel,
    AppConfigSubStatusModel,
} from "@/src/types/appConfig"

import ServerError from "@/src/pages/error/500"
import { useAuthContext } from "@/src/auth/hooks"
import { useMemo, useEffect, useCallback } from "react"
import axiosInstance, { endpoints } from "@/src/utils/axios"
import { LoadingScreen } from "@/src/components/loading-screen"
import { AppConfigContext } from "@/src/config/appConfigContext"

import { useSetState } from "src/hooks/use-set-state"

// eslint-disable-next-line import/no-named-as-default

type Props = {
    children: ReactNode
}

export function AppConfigProvider({ children }: Props) {
    const { user } = useAuthContext()
    const { state, setState } = useSetState<AppConfigState>({
        appConfig: null as any,
        loading: true,
    })

    const apiToConfigMapper = useCallback((response: any): AppConfig => {
        const mapSubStatus = (subStatus: any): AppConfigSubStatusModel => {
            const out = {
                ...subStatus,
                chipColour: subStatus.subStatusChipColour,
                colour: subStatus.subStatusColour,
                reasonsList: subStatus.reasons?.map((e: any) => e.reason),
                reasons: subStatus.reasons?.map((e: any) => ({ label: e.reasonDisplayName, value: e.reason })),
                label: subStatus.subStatusDisplayName,
                value: subStatus.subStatus,
            }
            delete out.subStatus
            delete out.subStatusDisplayName
            delete out.subStatusChipColour
            delete out.subStatusColour
            return out
        }

        const mapSingleStatus = (name: string, status: any): AppConfigStatusModel => {
            const out = {
                ...status,
                label: status.displayName,
                value: name,
                subStatuses: status.subStatuses?.map((e: any) => mapSubStatus(e)),
                subStatusList: status.subStatuses?.map((e: any) => e.subStatus),
            }
            delete out.displayName
            return out
        }

        const mapStatuses = (statuses: any) => {
            const out: any = {}
            Object.entries(statuses).forEach(([name, status]) => {
                out[name] = mapSingleStatus(name, status)
            })
            return out
        }

        const mapConfig = (
            config: any
        ): { data: AppConfigStatusModel; list: string[]; listObj: AppConfigStatusType[] } => {
            const mainData = mapStatuses(config)
            return {
                data: mainData,
                list: Object.keys(config),
                listObj: Object.values(mainData) as AppConfigStatusType[],
            }
        }

        const configs = response?.configs

        return {
            CLIENT_STATUS: mapConfig(configs.CLIENT_STATUS),
            LEAD_STATUS: mapConfig(configs.LEAD_STATUS),
            INTERVIEW_MODE: mapConfig(configs.INTERVIEW_MODE),
            SOURCE: mapConfig(configs.SOURCE),
            JOB_TYPE: mapConfig(configs.JOB_TYPE),
            WORK_MODE: mapConfig(configs.WORK_MODE),
            SELECTION_STATUS: mapConfig(configs.SELECTION_STATUS),
            INTERVIEW_STATUS: mapConfig(configs.INTERVIEW_STATUS),
            MANDATE_STATUS: mapConfig(configs.MANDATE_STATUS),
            EDUCATION_LEVEL: mapConfig(configs.EDUCATION_LEVEL),
            ENGLISH_LEVEL: mapConfig(configs.ENGLISH_LEVEL),
            MANDATE_PLAN: mapConfig(configs.MANDATE_PLAN),
            MANDATE_ATTRIBUTE: mapConfig(configs.MANDATE_ATTRIBUTE),
            LEAD_TYPE: mapConfig(configs.LEAD_TYPE),
            enableExpressMandateUi: response.enableExpressMandateUi,
            enableAIProcessing: response.enableAIProcessing,
        }
    }, [])

    const getAppConfig = useCallback(async () => {
        try {
            const res = await axiosInstance.get(endpoints.config.appConfig)
            const config = apiToConfigMapper(res.data?.data)

            setState({ appConfig: config, loading: false })
        } catch (error) {
            console.error(error)
            setState({ appConfig: null as any, loading: false })
        }
    }, [apiToConfigMapper, setState])

    useEffect(() => {
        if (user?.id) getAppConfig()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    // ----------------------------------------------------------------------

    const memoizedValue = useMemo(
        () => ({
            appConfig: state.appConfig,
            fetchConfig: getAppConfig,
            loading: state.loading,
        }),
        [getAppConfig, state]
    )

    return (
        <AppConfigContext.Provider value={memoizedValue}>
            {user ? state.loading ? <LoadingScreen /> : state.appConfig ? children : <ServerError /> : children}
        </AppConfigContext.Provider>
    )
}
